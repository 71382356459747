
  import { Component, Prop } from 'vue-property-decorator'
  import Row from './row.vue'

@Component({
  components: {
    Row,
  },
})
  export default class Simple extends Row {
  @Prop({ type: Boolean, default: false }) hiddenLabel!: boolean;
  }
