
  import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
  export default class TaskDetail extends Vue {
  @Prop({ type: Object, default: null }) info!: { lead: null, task: null }

  get purchaseNotes () {
    return this.info.task?.notes?.filter(note => note.deal.type.name === 'purchase')
  }

  get saleNotes () {
    return this.info.task?.notes?.filter(note => note.deal.type.name === 'sale')
  }
  }
