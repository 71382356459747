var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"time-slot",class:{
    'border': _vm.displayTime(_vm.time) !== '' && !_vm.borderTop && !(_vm.agendaBorderTop || _vm.agendaBorderBottom || _vm.agendaBorderSides) && !_vm.isSelected,
    'rounded-t-lg': _vm.borderTop,
    'rounded-b-lg': _vm.borderBottom,
  },style:({
    'cursor': (_vm.isSelectable && _vm.allowSelect && _vm.timeSelectable) ? 'pointer' : ''}),attrs:{"no-gutters":""},on:{"mousedown":_vm.onMouseDown,"mouseup":_vm.onMouseUp,"dragstart":function($event){$event.preventDefault();$event.stopPropagation();}}},[_c('v-col',{staticClass:"pa-0 pl-2",class:{ 'disabled-slot': !_vm.timeSelectable || _vm.selected },attrs:{"cols":"2"}},[_c('span',{staticClass:"text-body-2 text-end no-background"},[_vm._v(_vm._s(_vm.time))])]),_c('v-col',{staticClass:"no-background",class:{
      'disabled-slot-card': !(_vm.isSelectable && _vm.allowSelect) && !_vm.selected,
      'rounded-t-lg': _vm.borderSelectedTop || _vm.borderTop || _vm.agendaBorderTop,
      'rounded-b-lg': _vm.borderSelectedBottom || _vm.borderBottom || _vm.agendaBorderBottom,
      'borderSelectedTop': _vm.agendaBorderTop,
      'borderSelectedBottom': _vm.agendaBorderBottom,
      'borderSelected': _vm.agendaBorderSides,
      'newAgendaBorderSelectedTop': _vm.newAgendaBorderTop,
      'newAgendaBorderSelectedBottom rounded-b-lg': _vm.newAgendaBorderBottom,
      'newAgendaBorderSelected': _vm.newAgendaBorderSides
    },style:({
      '--border': _vm.newAgendaTitle.color,
      cursor: _vm.isClickable ? 'pointer' : '',
    }),attrs:{"cols":"10"},on:{"click":function($event){_vm.isClickable ? _vm.$emit('open-detail', { agenda: _vm.agenda, time: _vm.time }) : ''}}},[(!_vm.selected && !_vm.newAgendaIsSelected)?_c('v-divider',{staticClass:"divider",attrs:{"vertical":""}}):_vm._e(),(_vm.agenda)?[(_vm.agenda.timeStart === _vm.time)?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-icon',{staticClass:"px-2",attrs:{"color":_vm.agendaColor,"size":"18"}},[_vm._v(" "+_vm._s(_vm.agendaIcon)+" ")]),_c('span',{staticClass:"text-body-2 gray--text px-1"},[_vm._v(" "+_vm._s(_vm.agenda.task.client)+" ")]),_c('v-spacer'),_c('v-icon',{staticClass:"px-1",attrs:{"color":_vm.agendaPipelineColor,"size":"17"}},[_vm._v(" "+_vm._s(_vm.agendaPipelineIcon)+" ")])],1):_vm._e()]:(_vm.isSelected && _vm.isNewAgendaStart)?[_c('div',{staticClass:"d-flex justify-start align-center"},[_c('v-icon',{staticClass:"px-2",attrs:{"color":_vm.newAgendaTitle.color,"size":"18"}},[_vm._v(" "+_vm._s(_vm.newAgendaTitle.icon)+" ")]),_c('span',{staticClass:"text-body-2 font-weight-bold no-background gray--text px-1"},[_vm._v(_vm._s(_vm.newAgendaTitle.description))]),_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.newAgenda.timeStart)+"-"+_vm._s(_vm.newAgenda.timeEnd)+" ")])],1)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }