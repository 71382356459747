
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { mapActions } from 'vuex'

@Component({
  methods: {
    ...mapActions('user', ['logout']),
  },
})
  export default class LogOut extends Vue {
  @Prop({ type: String, default: null }) readonly color!: string;
  @Prop({ type: Boolean, default: true }) readonly icon!: boolean;
  logout!: () => void;

  signOut (): void {
    this.logout()
  }
  }
