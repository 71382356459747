
  import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
  import TimeSlot from './TimeSlot.vue'
  import dayjs from 'dayjs'
  import 'dayjs/locale/es'
  import { capitalize, fixDate } from '@/utils/general'
  import { ActivityType, Lead, LeadActivity } from '@/entities/crm'
  import AgendaTitle from '@/components/forms/fields/agenda/AgendaTitle.vue'
  import { mapActions } from 'vuex'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'
  import TaskDetail from '@/components/forms/fields/agenda/TaskDetail.vue'

  dayjs.locale('es')

@Component({
  components: {
    TaskDetail,
    AgendaTitle,
    TimeSlot,
  },
  methods: {
    ...mapActions('resources/form', ['fetchData']),
  },
})
  export default class Agenda extends Vue {
  @Ref() readonly agendaTitle!: AgendaTitle;
  @Prop({ type: Number, default: 0 }) height!: number;
  @Prop({ type: String, required: true }) startHour!: string;
  @Prop({ type: Boolean, required: true }) timeIsNotAvailable!: boolean;
  @Prop({ type: String, required: true }) endHour!: string;
  @Prop({ type: Number, default: 5 }) slotInterval!: number;
  @Prop({ type: Array, default: () => [] })
  agendas!: Array<LeadActivity>;

  @Prop({ type: Object, default: null }) newAgenda!: {
    date: string;
    title: ActivityType;
    allowSelect: boolean;
    lead: Lead;
  };

  currentTime = dayjs().format('HH:mm');
  newAgendaSelection = {};
  slotsEmpty = false;
  timeSlots: string[] = [];
  startSelectionIndex: number | null = null;
  range = [];
  leadDetail: { lead: Lead | null; task: LeadActivity | null } = {
    lead: null,
    task: null,
  };

  openDetail = false;
  positionX = 0;
  positionY = 0;
  maxHeight = 0;
  $refs!: {
    timeSlots: TimeSlot[];
  };

  fetchData!: (payload: {
    query: Query;
    filter?: formFilter;
    offset?: number;
    limit?: number;
    force?: boolean;
  }) => Promise<any>;

  @Watch('newAgenda', { immediate: true, deep: true })
  onSelectedDateChanged () {
    this.slotsEmpty = false
    this.cleanSelection()
    this.generateTimeSlots()
    this.$emit('time-range-selected', {
      start: null,
      end: null,
    })
    this.setMaxHeight()
    this.$emit('change')
  }

  created () {
    this.generateTimeSlots()
  }

  mounted () {
    this.getCurrenTime()
    this.setMaxHeight()
  }

  getCurrenTime () {
    this.updateCurrentTime()
    setInterval(() => {
      this.updateCurrentTime()
    }, 60000)
  }

  isSlotInRange (slot: string, agenda: LeadActivity, newAgenda: string, selected = false) {
    const agendaStartTime = dayjs(`${fixDate(newAgenda)} ${agenda.timeStart}`)
    const agendaEndTime = dayjs(`${fixDate(newAgenda)} ${agenda.timeEnd}`)
    const slotTime = dayjs(`${fixDate(newAgenda)} ${slot}`)

    return (
      ((selected && slotTime.isSame(agendaStartTime)) ||
        slotTime.isSame(agendaEndTime) ||
        slotTime.isAfter(agendaStartTime)) &&
      slotTime.isBefore(agendaEndTime)
    )
  }

  isTimeSlotSelected (slot: string) {
    const { newAgenda } = this
    return this.agendas.some(agenda => this.isSlotInRange(slot, agenda, newAgenda.date, true))
  }

  isAgendaInterval (slot) {
    const { agendas } = this

    return agendas.find(agenda => slot >= agenda.timeStart && slot < agenda.timeEnd)
  }

  slotColor (slot: string) {
    const { newAgenda, agendas } = this

    for (const agenda of agendas) {
      if (this.isSlotInRange(slot, agenda, newAgenda.date, true)) {
        return agenda.task.type.color
      }
    }
    return undefined
  }

  cleanSelection (index: number | null = null) {
    const { range } = this

    if (range?.length) {
      range.forEach(pos => {
        this.$refs.timeSlots[pos].isSelected = false
        this.$refs.timeSlots[pos].borderSelectedTop = false
        this.$refs.timeSlots[pos].borderSelectedBottom = false
      })
    }
    this.range = []
    if (index !== null) {
      this.range.push(index)
    }
  }

  onMouseDown (index: number) {
    this.cleanSelection(index)
    this.startSelectionIndex = index
  }

  onMouseUp (index: number) {
    let indexMax = index
    let selectionIncludesAgenda = false
    const { newAgenda, slotInterval, startSelectionIndex, timeSlots, agendas } = this

    if (newAgenda?.title.defaultTime) {
      const minutesToAdd = startSelectionIndex + (newAgenda.title.defaultTime / slotInterval)

      if (index < minutesToAdd) {
        indexMax = minutesToAdd
      }
    }

    if (indexMax >= timeSlots.length) return

    for (let i = startSelectionIndex + 1; i <= indexMax; i++) {
      if (agendas.some(agenda => this.isSlotInRange(timeSlots[i], agenda, newAgenda.date))) {
        selectionIncludesAgenda = true
        break
      }
    }

    if (!selectionIncludesAgenda) {
      this.setRefs(indexMax)
    } else {
      this.cleanSelection()
    }
  }

  setRefs (index) {
    if (index === null || this.startSelectionIndex === null) return

    this.$refs.timeSlots[this.startSelectionIndex].isSelected = true
    this.$refs.timeSlots[this.startSelectionIndex].borderSelectedTop = true
    this.$refs.timeSlots[this.startSelectionIndex].borderSelectedBottom = false
    for (let i = this.startSelectionIndex + 1; i <= index; i++) {
      this.$refs.timeSlots[i].isSelected = true
      this.range.push(i)
    }

    this.$refs.timeSlots[index].borderSelectedBottom = !this.agendas.some(agenda => this.isSlotInRange(this.timeSlots[index], agenda, this.newAgenda.date, true))
    this.startSelectionIndex = null
    this.setNewAgenda(this.range[0], this.range[this.range.length - 1], index)
  }

  setNewAgenda (start, end, index) {
    const { newAgenda } = this

    const lastTime = dayjs(`${fixDate(newAgenda.date)} ${this.timeSlots[index]}`).add(this.slotInterval, 'minutes').format('HH:mm')
    const timeStart = this.$refs.timeSlots[start].time
    const timeEnd = start === end ? this.$refs.timeSlots[end + 1]?.time || lastTime : this.$refs.timeSlots[end].time
    this.newAgendaSelection = {
      ...this.newAgenda,
      timeStart,
      timeEnd,
    }
    this.$emit('time-range-selected', {
      start: timeStart,
      end: timeEnd,
    })
  }

  generateTimeSlots () {
    if (!this.newAgenda?.date) return

    // Parsear la hora de finalización
    const [endHourPart, endMinutePart] = this.endHour.split(':').map(Number)

    // Añadir slotInterval a los minutos para permitir la seleccion con el horario final configurado
    let adjustedMinutes = endMinutePart + this.slotInterval

    // Si los minutos exceden de 59, establecerlos en 55 para mantener el multiple de 5
    if (adjustedMinutes > 59) {
      adjustedMinutes = 55
    }

    // Formatear los minutos para que tengan dos dígitos
    const adjustedMinutesStr = adjustedMinutes < 10 ? `0${adjustedMinutes}` : `${adjustedMinutes}`

    // Crear el tiempo final ajustado
    const finalTime = `${endHourPart}:${adjustedMinutesStr}`

    // Crear objetos dayjs para el inicio y fin
    const startTime = dayjs(`${fixDate(this.newAgenda.date)} ${this.startHour}`)
    const endTime = dayjs(`${fixDate(this.newAgenda.date)} ${finalTime}`)

    const timeSlots = []

    let currentTime = startTime
    while (currentTime.isBefore(endTime)) {
      timeSlots.push(currentTime.format('HH:mm'))
      currentTime = currentTime.add(this.slotInterval, 'minute')
    }

    this.timeSlots = timeSlots
  }

  updateCurrentTime () {
    this.currentTime = dayjs().format('HH:mm')
  }

  setMaxHeight () {
    const { agendaTitle, height } = this
    const marginTop = 70
    const agendaHeight = (agendaTitle?.$el?.clientHeight ?? 0) + marginTop

    this.maxHeight = height - agendaHeight
  }

  get allowCreate () {
    const { newAgenda } = this

    return newAgenda?.allowSelect
  }

  get dateFormatted () {
    const { newAgenda } = this
    if (!newAgenda?.date) {
      return ''
    }

    return capitalize(dayjs(fixDate(newAgenda.date)).format('dddd, D MMMM YYYY'))
  }

  get isPastDate () {
    const { newAgenda } = this
    const now = dayjs().startOf('day')
    const dateFormatted = dayjs(fixDate(newAgenda?.date), 'DD/MM/YYYY')

    return dateFormatted.isBefore(now)
  }

  get timeOver () {
    const { currentTime, endHour, newAgenda } = this
    const now = dayjs().startOf('day')
    const dateFormatted = dayjs(fixDate(newAgenda?.date), 'DD/MM/YYYY')

    if (dateFormatted.isAfter(now) && !dateFormatted.isSame(now)) {
      return false
    }

    const newTime = dayjs(`${now.format('DD/MM/YYYY')} ${currentTime}`, 'HH:mm')
    const timeFormatted = dayjs(`${now.format('DD/MM/YYYY')} ${endHour}`, 'HH:mm')

    return timeFormatted.isBefore(newTime)
  }

  removeSlot (slot) {
    const pos = this.timeSlots.findIndex(timeSlot => timeSlot === slot)
    if (pos !== -1) {
      this.timeSlots.splice(pos, 1)
    }
    if (!this.timeSlots.length) {
      this.slotsEmpty = true
    }
  }

  async getLeadDetail ({ agenda, time }) {
    if (agenda.lead.id) {
      this.leadDetail.lead = await this.fetchData({
        query: { name: 'fetch', model: 'Lead', params: { id: agenda.lead.id } },
        force: true,
      })
      this.positionX = 0
      this.positionY = 0
      const pos = this.timeSlots.findIndex(slot => slot === time)

      this.$nextTick(() => {
        const rect = this.$refs.timeSlots[pos].$el.getBoundingClientRect()
        this.positionX = rect.left + 40
        this.positionY = rect.top - 180
      })

      this.leadDetail.task = agenda.task
      this.openDetail = true
    }
  }

  closeLeadDetail () {
    this.openDetail = false
    this.leadDetail = { lead: null, task: null }
  }

  @Watch('$vuetify.breakpoint', { immediate: true, deep: true })
  onResize () {
    this.setMaxHeight()
  }

  @Watch('height', { immediate: true, deep: true })
  onHeightChange () {
    this.setMaxHeight()
  }
  }
