
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { fixPrice } from '@/utils/general'
  import { Correlation } from '@/models/financing/Correlation'

@Component({
  methods: {
    fixPrice,
  },
})
  export default class InitialFields extends Vue {
  @Prop({
    type: [Object, String],
    default: () => {
    },
  }) value: Correlation

  @Prop({ type: Number, required: true }) total: number
  @Prop({ type: Number, default: 0 }) initial: number
  @Prop({ type: Number, default: 0 }) min: number
  @Prop({ type: Number, default: 0 }) maxAmount: number
  @Prop({ type: Boolean, default: false }) disabled: boolean
  @Prop({ type: Boolean, default: false }) initialized: boolean
  @Prop({ type: Boolean, default: false }) slider: boolean

  pieRules = [
    v => v && v !== 0 ? (v >= 20 || 'Pie mínimo 20%') : true,
  ]

  amountLength = 0
  data: Correlation | null = null

  mounted () {
    const { initialized, value } = this

    if (!value?.perc) {
      if (initialized) {
        this.data = new Correlation(this.total, this.initial)
      } else {
        this.data = new Correlation(this.total, 0)
      }
      this.emit()
    } else {
      this.data = value
    }
  }

  get recommendPie () {
    const { data } = this

    return data.perc >= 40 ? '' : 'Pie recomendado 40%'
  }

  private emit () {
    this.$emit('input', this.data)
  }

  setAmount ({ target: { value } }) {
    this.data.amount = value
    this.emit()
  }

  setPerc (e) {
    this.data.perc = e
    this.emit()
  }

  repeatWithThousandSeparator (char, num) {
    const repeatedString = char.repeat(num)
    const numberValue = Number(repeatedString)
    if (isNaN(numberValue)) {
      throw new Error(`Invalid character for repetition: ${char}`)
    }
    return numberValue.toLocaleString().replaceAll('1', '#').replaceAll(',', '.')
  }

  maxValue (v) {
    const { maxAmount, amountLength } = this
    if (!maxAmount || maxAmount < 0) return true

    if (typeof v === 'string') {
      if (v === '' || v === '0') {
        return `Pie mínimo ${fixPrice(maxAmount * 0.2)}`
      }
      const value = v.toString()
      const newValue = Number(value.slice(0, amountLength) + '.' + value.slice(amountLength))

      return newValue <= maxAmount || `Pie máximo ${fixPrice(maxAmount)}`
    }
    if (v === 0) {
      return `Pie mínimo ${fixPrice(maxAmount * 0.2)}`
    }
    return v <= maxAmount || `Pie máximo ${fixPrice(maxAmount)}`
  }

  @Watch('total', { immediate: true })
  onTotalChange (total) {
    const { value, initial } = this
    const initialCalculated = value?.perc || initial
    this.data = new Correlation(total, initialCalculated)
    this.emit()
  }

  @Watch('initial', { immediate: true })
  onInitialChange (initial) {
    if (initial) {
      this.data = new Correlation(this.total, initial)
      this.emit()
    }
  }
  }
